
import { defineComponent, onMounted, watch } from 'vue'
import * as echarts from 'echarts'
import chinaJson from '@/assets/map-json/china.json'
import sichuanJson from '@/assets/map-json/sichuan.json'
import chongqingJson from '@/assets/map-json/chongqing.json'

export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup(props) {
    // 区域地图经纬度数据
    const mapJson = {
      china: chinaJson,
      sichuan: sichuanJson,
      chongqing: chongqingJson
    }
    /**
     * 区域数据
     * value: 碳排放总量
     * energy: 能源总量
     * gdp: GDP总量
     * raking: 排名
     */
    const data = {
      china: [
        { name: '四川', value: 1.34, energy: 10, gdp: 10, ranking: 1 },
        { name: '重庆', value: 48000.48, energy: 10, gdp: 10, ranking: 1 }
      ],
      sichuan: [
        { name: '成都市', value: 50000, energy: 10, gdp: 10, ranking: 1 },
        { name: '自贡市', value: 1000, energy: 10, gdp: 10, ranking: 1 },
        { name: '攀枝花市', value: 0, energy: 10, gdp: 10, ranking: 1 },
        { name: '泸州市', value: 3000, energy: 10, gdp: 10, ranking: 1 },
        { name: '德阳市', value: 4000, energy: 10, gdp: 10, ranking: 1 },
        { name: '绵阳市', value: 5000, energy: 10, gdp: 10, ranking: 1 },
        { name: '广元市', value: 6000, energy: 10, gdp: 10, ranking: 1 },
        { name: '遂宁市', value: 7000, energy: 10, gdp: 10, ranking: 1 },
        { name: '内江市', value: 8000, energy: 10, gdp: 10, ranking: 1 },
        { name: '乐山市', value: 9000, energy: 10, gdp: 10, ranking: 1 },
        { name: '南充市', value: 10000, energy: 10, gdp: 10, ranking: 1 },
        { name: '眉山市', value: 11000, energy: 10, gdp: 10, ranking: 1 },
        { name: '宜宾市', value: 12000, energy: 10, gdp: 10, ranking: 1 },
        { name: '广安市', value: 13000, energy: 10, gdp: 10, ranking: 1 },
        { name: '达州市', value: 14000, energy: 10, gdp: 10, ranking: 1 },
        { name: '雅安市', value: 15000, energy: 10, gdp: 10, ranking: 1 },
        { name: '巴中市', value: 16000, energy: 10, gdp: 10, ranking: 1 },
        { name: '资阳市', value: 17000, energy: 10, gdp: 10, ranking: 1 },
        { name: '阿坝藏族羌族自治州', value: 18000, energy: 10, gdp: 10, ranking: 1 },
        { name: '甘孜藏族自治州', value: 19000, energy: 10, gdp: 10, ranking: 1 },
        { name: '凉山彝族自治州', value: 20000, energy: 10, gdp: 10, ranking: 1 }
      ],
      chongqing: [
        { name: '万州区', value: 0, energy: 10, gdp: 10, ranking: 1 },
        { name: '涪陵区', value: 1000, energy: 10, gdp: 10, ranking: 1 },
        { name: '渝中区', value: 2000, energy: 10, gdp: 10, ranking: 1 },
        { name: '大渡口区', value: 3000, energy: 10, gdp: 10, ranking: 1 },
        { name: '江北区', value: 4000, energy: 10, gdp: 10, ranking: 1 },
        { name: '沙坪坝区', value: 5000, energy: 10, gdp: 10, ranking: 1 },
        { name: '九龙坡区', value: 6000, energy: 10, gdp: 10, ranking: 1 },
        { name: '南岸区', value: 7000, energy: 10, gdp: 10, ranking: 1 },
        { name: '北碚区', value: 8000, energy: 10, gdp: 10, ranking: 1 },
        { name: '綦江区', value: 9000, energy: 10, gdp: 10, ranking: 1 },
        { name: '大足区', value: 10000, energy: 10, gdp: 10, ranking: 1 },
        { name: '渝北区', value: 11000, energy: 10, gdp: 10, ranking: 1 },
        { name: '巴南区', value: 12000, energy: 10, gdp: 10, ranking: 1 },
        { name: '黔江区', value: 13000, energy: 10, gdp: 10, ranking: 1 },
        { name: '长寿区', value: 14000, energy: 10, gdp: 10, ranking: 1 },
        { name: '江津区', value: 15000, energy: 10, gdp: 10, ranking: 1 },
        { name: '合川区', value: 16000, energy: 10, gdp: 10, ranking: 1 },
        { name: '永川区', value: 17000, energy: 10, gdp: 10, ranking: 1 },
        { name: '南川区', value: 18000, energy: 10, gdp: 10, ranking: 1 },
        { name: '璧山区', value: 19000, energy: 10, gdp: 10, ranking: 1 },
        { name: '铜梁区', value: 20000, energy: 10, gdp: 10, ranking: 1 },
        { name: '潼南区', value: 21000, energy: 10, gdp: 10, ranking: 1 },
        { name: '荣昌区', value: 22000, energy: 10, gdp: 10, ranking: 1 },
        { name: '梁平县', value: 23000, energy: 10, gdp: 10, ranking: 1 },
        { name: '城口县', value: 24000, energy: 10, gdp: 10, ranking: 1 },
        { name: '丰都县', value: 25000, energy: 10, gdp: 10, ranking: 1 },
        { name: '垫江县', value: 26000, energy: 10, gdp: 10, ranking: 1 },
        { name: '武隆县', value: 27000, energy: 10, gdp: 10, ranking: 1 },
        { name: '忠县', value: 28000, energy: 10, gdp: 10, ranking: 1 },
        { name: '云阳县', value: 29000, energy: 10, gdp: 10, ranking: 1 },
        { name: '奉节县', value: 30000, energy: 10, gdp: 10, ranking: 1 },
        { name: '巫山县', value: 31000, energy: 10, gdp: 10, ranking: 1 },
        { name: '巫溪县', value: 32000, energy: 10, gdp: 10, ranking: 1 },
        { name: '石柱土家族自治县', value: 33000, energy: 10, gdp: 10, ranking: 1 },
        { name: '秀山土家族苗族自治县', value: 34000, energy: 10, gdp: 10, ranking: 1 },
        { name: '酉阳土家族苗族自治县', value: 35000, energy: 10, gdp: 10, ranking: 1 },
        { name: '彭水苗族土家族自治县', value: 36000, energy: 10, gdp: 10, ranking: 1 },
        { name: '开州区', value: 50000, energy: 10, gdp: 10, ranking: 1 }
      ]
    }
    // 地图组件
    let mapBox = null

    // 生命周期
    onMounted(() => initMap())

    // 监听区域变化，重新渲染地图
    watch(
      () => props.area,
      () => renderMap()
    )

    // 初始化地图
    const initMap = () => {
      mapBox = echarts.init(document.getElementById('map-box'))
      renderMap()
    }

    // 渲染地图
    const renderMap = () => {
      echarts.registerMap(props.area as string, mapJson[props.area as string])
      mapBox.setOption({
        tooltip: {
          trigger: 'item',
          formatter: ({ name, data }) => {
            if (!name || name === '南海诸岛') {
              return
            }
            return `${name}
                    <br/>碳排放量：${data.value} tCO2e
                    <br/>能耗总量：${data.energy} wt标煤
                    <br/>GDP总量：${data.gdp} 亿元
                    <br/>排名：${data.ranking}
                    `
          }
        },
        visualMap: {
          min: 0,
          max: 50000,
          text: ['最高', '最低'],
          realtime: false,
          calculable: true,
          left: '30px',
          bottom: '30px',
          textStyle: {
            color: '#fff'
          },
          inRange: {
            color: ['#4DBB33', 'orangered', '#EE0000']
          }
        },
        series: [
          {
            type: 'map',
            name: '',
            map: props.area,
            selectedMode: false, // 关闭选中模式
            label: {
              show: true
            },
            itemStyle: {
              borderWidth: 1, // 边际线大小
              borderColor: '#A5E4F2', // 边界线颜色
              areaColor: 'transparent' // 默认区域颜色
            },
            emphasis: {
              disabled: true // 关闭高亮
            },
            left: '20%',
            top: '5%',
            right: '20%',
            bottom: '5%',
            data: data[props.area as string]
          }
        ]
      })
    }
  }
})
