
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    width: {
      type: String,
      default: '180px'
    },
    label: {
      type: String,
      default: ''
    },
    rate: {
      type: Number,
      default: 0.5
    },
    value: {
      type: String,
      default: '0',
    },
    unit: {
      type: String,
      default: ""
    },
    colorLeft: {
      type: String,
      default: "rgba(2,68,31,0.24)",
    },
    colorRight: {
      type: String,
      default: "rgba(28,179,94,0.8)",
    },
    hueRotate: {
      type: Number,
      default: 0
    },
  },
  setup(props) {

    const left = computed(() => {
      const { width, rate } = props
      return (parseInt(width as string) * (props.rate as number)) + 'px';

    })
    const letBar = computed(() => {
      return {
        width: left.value,
        backgroundImage: `linear-gradient(to right,${props.colorLeft},${props.colorRight})`
      }
    })
    const poitonLeft = computed(() => {
      return {
        left: left.value,
        filter: `brightness(130%) saturate(110%) hue-rotate(${props.hueRotate}deg)`
      }
    })
    return {
      letBar,
      poitonLeft
    }
  }
})
