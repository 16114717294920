
import NumberCard from '@/components/atom/NumberCard.vue'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  components: { NumberCard },
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup(props) {
    /**
     * 区域数据
     * 燃煤、电量、燃油、燃气、蒸汽
     */
    const dataJson = {
      china: [212, 200, 180, 98, 75],
      sichuan: [112, 100, 80, 198, 175],
      chongqing: [212, 200, 180, 98, 75]
    }

    const config = ref<any>({})

    watch(
      () => props.area,
      () => {
        const data = dataJson[props.area as string]
        config.value = {
          data: [
            {
              name: '燃煤',
              value: data[0]
            },
            {
              name: '电量',
              value: data[1]
            },
            {
              name: '燃油',
              value: data[2]
            },
            {
              name: '燃气',
              value: data[3]
            },
            {
              name: '蒸汽',
              value: data[4]
            }
          ],
          colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff']
        }
      },
      {
        immediate: true
      }
    )

    return {
      config
    }
  }
})
