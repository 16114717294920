import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66636b58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "labels" }
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dv_charts = _resolveComponent("dv-charts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dv_charts, {
      option: _ctx.options,
      class: "chart-box"
    }, null, 8, ["option"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "item",
          key: index
        }, [
          _createElementVNode("span", {
            class: "iconfont icon-luyin",
            style: _normalizeStyle({ color: item.color })
          }, null, 4),
          _createElementVNode("span", _hoisted_3, _toDisplayString(item.label), 1)
        ]))
      }), 128))
    ])
  ]))
}