import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40e473ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "item" }
const _hoisted_3 = { class: "item-desc" }
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { class: "item-desc" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart_svg = _resolveComponent("chart-svg")!
  const _component_km_slider = _resolveComponent("km-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_chart_svg, { hueRotate: 255 }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_km_slider, {
          class: "slider",
          hueRotate: 255,
          label: "年预计排放",
          value: _ctx.dataJson[_ctx.area].nyjpf.value,
          rate: _ctx.dataJson[_ctx.area].nyjpf.rate,
          unit: "CO2e"
        }, null, 8, ["value", "rate"]),
        _createVNode(_component_km_slider, {
          class: "slider",
          hueRotate: 255,
          label: "当前排放",
          value: _ctx.dataJson[_ctx.area].dqpf.value,
          rate: _ctx.dataJson[_ctx.area].dqpf.rate,
          unit: "CO2e"
        }, null, 8, ["value", "rate"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_chart_svg, { hueRotate: 181 }),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_km_slider, {
          class: "slider",
          colorLeft: "#40110145",
          colorRight: "#d54210eb",
          hueRotate: 181,
          label: "年规划耗能",
          value: _ctx.dataJson[_ctx.area].nghnh.value,
          rate: _ctx.dataJson[_ctx.area].nghnh.rate,
          unit: "wt标煤"
        }, null, 8, ["value", "rate"]),
        _createVNode(_component_km_slider, {
          class: "slider",
          colorLeft: "#40110145",
          colorRight: "#d54210eb",
          hueRotate: 181,
          label: "当前耗能",
          value: _ctx.dataJson[_ctx.area].dqnh.value,
          rate: _ctx.dataJson[_ctx.area].dqnh.rate,
          unit: "wt标煤"
        }, null, 8, ["value", "rate"])
      ])
    ])
  ]))
}