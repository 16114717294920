
import { defineComponent, ref, watch } from 'vue'
export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup(props) {
    // 区域数据
    const dataJson = {
      china: [
        [ 1, 11330, 11430, 11530, 11830, 11930, 12150, 12230, 12330, 12430, 12230, 12230 ], // 发电
        [ 11230, 10830, 11430, 10430, 10930, 10530, 10480, 10630, 10260, 10230, 14230, 14230 ], // 石化
        [ 14230, 14330, 14430, 14530, 14830, 14930, 14150, 14230, 14330, 14430, 14230, 14230 ], // 化工
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 建材
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 钢铁
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 有色金属
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 造纸
        [ 0, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ] // 航空
      ],
      sichuan: [
        [ 11111111, 11330, 11430, 11530, 11830, 11930, 12150, 12230, 12330, 12430, 12230, 12230 ], // 发电
        [ 11230, 10830, 11430, 10430, 10930, 10530, 10480, 10630, 10260, 10230, 14230, 14230 ], // 石化
        [ 14230, 14330, 14430, 14530, 14830, 14930, 14150, 14230, 14330, 14430, 14230, 14230 ], // 化工
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 建材
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 钢铁
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 有色金属
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 造纸
        [ 10000, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ] // 航空
      ],
      chongqing: [
        [ 1, 11330, 11430, 11530, 11830, 11930, 12150, 12230, 12330, 12430, 12230, 12230 ], // 发电
        [ 11230, 10830, 11430, 10430, 10930, 10530, 10480, 10630, 10260, 10230, 14230, 14230 ], // 石化
        [ 14230, 14330, 14430, 14530, 14830, 14930, 14150, 14230, 14330, 14430, 14230, 14230 ], // 化工
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 建材
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 钢铁
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 有色金属
        [ 14230, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ], // 造纸
        [ 0, 13830, 14430, 13430, 13930, 13530, 13480, 13630, 13260, 13230, 14230, 14230 ] // 航空
      ]
    }

    const options = ref({})

    watch(
      () => props.area,
      () => {
        const data = dataJson[props.area as string]
        options.value = {
          title: {
            text: '',
            show: false,
            style: {
              fill: '#fff'
            }
          },
          xAxis: {
            data: [
              '1月',
              '2月',
              '3月',
              '4月',
              '5月',
              '6月',
              '7月',
              '8月',
              '9月',
              '10月',
              '11月',
              '12月'
            ],
            axisTick: {
              style: {
                stroke: 'rgb(44,22,161)',
                lineWidth: 0.5
              }
            },
            axisLabel: {
              style: {
                fill: '#fff'
              }
            }
          },
          yAxis: {
            name: '排放量',
            data: 'value',
            nameTextStyle: {
              fill: '#fff',
              fontSize: 10
            },
            axisLabel: {
              style: {
                fill: '#fff'
              }
            }
          },
          series: [
            {
              data: data[0],
              type: 'line',
              smooth: true,
              color: 'rgba(0, 255, 218)',
              lineArea: {
                show: true,
                gradient: ['rgba(0, 255, 218, 0.6)', 'rgba(0, 255, 218, 0)']
              }
            },
            {
              data: data[1],
              type: 'line',
              smooth: true,
              color: 'rgba(0, 140, 255)',
              lineArea: {
                show: true,
                gradient: ['rgba(0, 140, 255, 0.6)', 'rgba(0, 140, 255, 0)']
              }
            },
            {
              data: data[2],
              type: 'line',
              smooth: true,
              color: 'rgba(224, 98, 174)',
              lineArea: {
                show: true,
                gradient: ['rgba(224, 98, 174, 0.6)', 'rgba(224, 98, 174, 0)']
              }
            },
            {
              data: data[3],
              type: 'line',
              smooth: true,
              color: 'rgba(254, 98, 174)',
              lineArea: {
                show: true,
                gradient: ['rgba(254, 98, 174, 0.6)', 'rgba(254, 98, 174, 0)']
              }
            },
            {
              data: data[4],
              type: 'line',
              smooth: true,
              color: 'rgba(55, 162, 218)',
              lineArea: {
                show: true,
                gradient: ['rgba(55, 162, 218, 0.6)', 'rgba(55, 162, 218, 0)']
              }
            },
            {
              data: data[5],
              type: 'line',
              smooth: true,
              color: 'rgba(255, 219, 92)',
              lineArea: {
                show: true,
                gradient: ['rgba(255, 219, 92, 0.6)', 'rgba(255, 219, 92, 0)']
              }
            },
            {
              data: data[6],
              type: 'line',
              smooth: true,
              color: 'rgba(255, 159, 127)',
              lineArea: {
                show: true,
                gradient: ['rgba(255, 159, 127, 0.6)', 'rgba(255, 159, 127, 0)']
              }
            },
            {
              data: data[7],
              type: 'line',
              smooth: true,
              color: 'rgba(82, 85, 206)',
              lineArea: {
                show: true,
                gradient: ['rgba(82, 85, 206, 0.6)', 'rgba(82, 85, 206, 0)']
              }
            }
          ]
        }
      },
      {
        immediate: true
      }
    )

    const labels = ref([
      {
        label: '发电',
        color: 'rgba(0, 255, 218)'
      },
      {
        label: '石化',
        color: 'rgba(0, 140, 255)'
      },
      {
        label: '化工',
        color: 'rgba(224, 98, 174)'
      },
      {
        label: '建材',
        color: 'rgba(254, 98, 174)'
      },
      {
        label: '钢铁',
        color: 'rgba(55, 162, 218)'
      },
      {
        label: '有色金属',
        color: 'rgba(255, 219, 92)'
      },
      {
        label: '造纸',
        color: 'rgba(255, 159, 127)'
      },
      {
        label: '航空',
        color: 'rgba(82, 85, 206)'
      }
    ])
    return {
      options,
      labels
    }
  }
})
