
import { defineComponent, ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { formatTime } from '@/utils/index'
import { WEEK } from '@/constant/index'
import useDraw from '@/utils/useDraw'
import { title, subtitle, moduleInfo } from '@/constant/index'
import YearBox from '../year-box/YearBox.vue'
import IndexHeader from '../header/IndexHeader.vue'
import LeftBottom from '../left-bottom/LeftBottom.vue'
import LeftBottom2 from '../left-bottom/LeftBottom2.vue'
import CompanyBox from '../company-box/CompanyBox.vue'
import ChainBox from '../chain/ChainBox.vue'
import ChainDetail from '../chain/ChainDetail.vue'
import CompanyDesc from '../company-box/CompanyDesc.vue'
import MapBox from '../map/MapBox.vue'

export default defineComponent({
  components: {
    YearBox,
    IndexHeader,
    LeftBottom,
    LeftBottom2,
    CompanyBox,
    ChainBox,
    ChainDetail,
    CompanyDesc,
    MapBox
  },
  setup() {
    // * 加载标识
    const loading = ref<boolean>(true)
    // * 区域 china-全国 sichuan-四川 chongqing-重庆
    const area = ref<string>('china')
    // * 时间内容
    const timeInfo = reactive({
      setInterval: 0,
      dateDay: '',
      dateYear: '',
      dateWeek: ''
    })
    // * 中间部分总量
    const totalData = ref({
      china: {
        tpzl: 3125.23, // 碳排总量
        nhzl: 8125.23, // 能耗总量
        gdp: 8125.23 // GDP总量
      },
      sichuan: {
        tpzl: 125.23, // 碳排总量
        nhzl: 125.23, // 能耗总量
        gdp: 825.23 // GDP总量
      },
      chongqing: {
        tpzl: 3125.23, // 碳排总量
        nhzl: 8125.23, // 能耗总量
        gdp: 8125.23 // GDP总量
      }
    })
    // * 能源结构分析类型
    const nyjgfxType = ref(1)
    // * 适配处理
    const { appRef, calcRate, windowDraw, unWindowDraw } = useDraw()
    // 生命周期
    onMounted(() => {
      cancelLoading()
      handleTime()
      // todo 屏幕适应
      windowDraw()
      calcRate()
    })

    onBeforeUnmount(() => {
      unWindowDraw()
      clearInterval(timeInfo.setInterval)
    })

    // methods
    // todo 处理 loading 展示
    const cancelLoading = () => {
      setTimeout(() => {
        loading.value = false
      }, 150)
    }

    // todo 处理时间监听
    const handleTime = () => {
      timeInfo.setInterval = setInterval(() => {
        const date = new Date()
        timeInfo.dateDay = formatTime(date, 'HH: mm: ss')
        timeInfo.dateYear = formatTime(date, 'yyyy-MM-dd')
        timeInfo.dateWeek = WEEK[date.getDay()]
      }, 1000)
    }

    // todo 切换区域
    const onChangeArea = (value: string) => {
      if (value === area.value) return
      loading.value = true
      area.value = value
      cancelLoading()
    }

    // 页面跳转
    const onGo = (path: string) => {
      window.open(`http://47.108.53.44/admin/index.html#${path}?area=${area.value}`)
    }

    // return
    return {
      loading,
      timeInfo,
      totalData,
      nyjgfxType,
      appRef,
      area,
      title,
      subtitle,
      moduleInfo,
      onChangeArea,
      onGo
    }
  }
})
