
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup(props) {
    /**
     * 区域数据
     * 发电、石化、化工、建材、钢铁、有色金属、造纸、航空
     */
    const dataJson = {
      china: [
        [1800, 2230, 1900, 2100, 3500, 4200, 3985, 4200, 3985], // 去年
        [1750, 2130, 1800, 2000, 3400, 4100, 3885, 4100, 3885] // 今年
      ],
      sichuan: [
        [2800, 2230, 1900, 2100, 3500, 4200, 3985, 4200, 3985], // 去年
        [2750, 2130, 1800, 2000, 3400, 4100, 3885, 4100, 3885] // 今年
      ],
      chongqing: [
        [1800, 2230, 1900, 2100, 3500, 4200, 3985, 4200, 3985], // 去年
        [1750, 2130, 1800, 2000, 3400, 4100, 3885, 4100, 3885] // 今年
      ]
    }

    const options = ref({})

    watch(
      () => props.area,
      () => {
        const data = dataJson[props.area as string]
        options.value = {
          title: {
            show: false
          },
          xAxis: {
            data: [
              '发电',
              '石化',
              '化工',
              '建材',
              '钢铁',
              '有色金属',
              '造纸',
              '航空'
            ],
            axisTick: {
              style: {
                stroke: 'rgb(44,22,161)',
                lineWidth: 0.5
              }
            },
            axisLabel: {
              style: {
                fill: '#fff'
              }
            }
          },
          yAxis: {
            name: '排放量',
            data: 'value',
            nameTextStyle: {
              fill: '#fff',
              fontSize: 10
            },
            axisLabel: {
              style: {
                fill: '#fff'
              }
            }
          },
          series: [
            {
              name: '去年',
              data: data[0],
              type: 'bar',
              shapeType: 'leftEchelon',
              gradient: {
                color: [
                  'rgba(157, 162, 219, 0.878)',
                  'rgba(200, 229, 231, 0.204)'
                ]
              }
            },
            {
              name: '今年',
              data: data[1],
              type: 'bar',
              shapeType: 'rightEchelon',
              gradient: {
                color: [
                  'rgba(57, 162, 219, 0.878)',
                  'rgba(100, 229, 231, 0.204)'
                ]
              }
            }
          ]
        }
      },
      {
        immediate: true
      }
    )

    const labels = ref([
      {
        label: '去年',
        color: 'rgba(157, 162, 219)'
      },
      {
        label: '本年',
        color: 'rgba(57, 162, 219)'
      }
    ])
    return {
      options,
      labels
    }
  }
})
