
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    },
    // 类型：1-石化能源，2-可再生能源
    type: {
      type: Number,
      default: () => 1
    }
  },
  setup(props) {
    /**
     * 区域石化能源数据
     */
    const shDataJson = {
      china: [
        { name: '饲料加工', value: 212 },
        { name: '食品加工', value: 200 },
        { name: '货品运输', value: 180 },
        { name: '农牧加工', value: 98 },
        { name: '晶硅厂区', value: 75 },
        { name: '电池厂区', value: 66 }
      ],
      sichuan: [
        { name: '饲料加工1', value: 212 },
        { name: '食品加工', value: 200 },
        { name: '货品运输', value: 180 },
        { name: '农牧加工', value: 98 },
        { name: '晶硅厂区', value: 75 },
        { name: '电池厂区', value: 66 }
      ],
      chongqing: [
        { name: '饲料加工', value: 212 },
        { name: '食品加工', value: 200 },
        { name: '货品运输', value: 180 },
        { name: '农牧加工', value: 98 },
        { name: '晶硅厂区', value: 75 },
        { name: '电池厂区', value: 66 }
      ]
    }
    /**
     * 区域可再生能源数据
     */
    const kzsDataJson = {
      china: [
        { name: '可再生饲料加工', value: 212 },
        { name: '食品加工', value: 200 },
        { name: '货品运输', value: 180 },
        { name: '农牧加工', value: 98 },
        { name: '晶硅厂区', value: 75 },
        { name: '电池厂区', value: 66 }
      ],
      sichuan: [
        { name: '可再生饲料加工1', value: 212 },
        { name: '食品加工', value: 200 },
        { name: '货品运输', value: 180 },
        { name: '农牧加工', value: 98 },
        { name: '晶硅厂区', value: 75 },
        { name: '电池厂区', value: 66 }
      ],
      chongqing: [
        { name: '饲料加工', value: 212 },
        { name: '食品加工', value: 200 },
        { name: '货品运输', value: 180 },
        { name: '农牧加工', value: 98 },
        { name: '晶硅厂区', value: 75 },
        { name: '电池厂区', value: 66 }
      ]
    }

    const config = ref({})

    watch(
      () => [props.area, props.type],
      () => {
        config.value = {
          data:
            props.type === 1
              ? shDataJson[props.area as string]
              : kzsDataJson[props.area as string],
          unit: '千瓦时'
        }
      },
      {
        immediate: true
      }
    )

    return {
      config
    }
  }
})
