
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup(props) {
    /**
     * 区域数据
     * 达标企业、未达标企业
     */
    const dataJson = {
      china: [
        ['四川通威', '四川通威'],
        ['通威特种饲料', '通威特种饲料'],
        ['佛山市南海通威水产科技', '佛山市南海通威水产科技'],
        ['成都通威鱼有限公司', '成都通威鱼有限公司'],
        ['通威（海南）水产食品有限公司', '通威（海南）水产食品有限公司'],
        ['成都新太丰农业开发有限公司', '成都新太丰农业开发有限公司'],
        ['成都新太丰畜禽养殖', '成都新太丰畜禽养殖'],
        ['山东通威', '山东通威'],
        ['成都通威鱼有限公司', '成都通威鱼有限公司'],
        ['通威（海南）水产食品有限公司', '通威（海南）水产食品有限公司'],
        ['成都新太丰农业开发有限公司', '成都新太丰农业开发有限公司'],
        ['成都新太丰畜禽养殖', '成都新太丰畜禽养殖'],
        ['山东通威', '山东通威']
      ],
      sichuan: [
        ['四川通威1', '四川通威'],
        ['通威特种饲料1', '通威特种饲料'],
        ['佛山市南海通威水产科技', '佛山市南海通威水产科技'],
        ['成都通威鱼有限公司', '成都通威鱼有限公司'],
        ['通威（海南）水产食品有限公司', '通威（海南）水产食品有限公司'],
        ['成都新太丰农业开发有限公司', '成都新太丰农业开发有限公司'],
        ['成都新太丰畜禽养殖', '成都新太丰畜禽养殖'],
        ['山东通威', '山东通威'],
        ['成都通威鱼有限公司', '成都通威鱼有限公司'],
        ['通威（海南）水产食品有限公司', '通威（海南）水产食品有限公司'],
        ['成都新太丰农业开发有限公司', '成都新太丰农业开发有限公司'],
        ['成都新太丰畜禽养殖', '成都新太丰畜禽养殖'],
        ['山东通威', '山东通威']
      ],
      chongqing: [
        ['四川通威', '四川通威'],
        ['通威特种饲料', '通威特种饲料'],
        ['佛山市南海通威水产科技', '佛山市南海通威水产科技'],
        ['成都通威鱼有限公司', '成都通威鱼有限公司'],
        ['通威（海南）水产食品有限公司', '通威（海南）水产食品有限公司'],
        ['成都新太丰农业开发有限公司', '成都新太丰农业开发有限公司'],
        ['成都新太丰畜禽养殖', '成都新太丰畜禽养殖'],
        ['山东通威', '山东通威'],
        ['成都通威鱼有限公司', '成都通威鱼有限公司'],
        ['通威（海南）水产食品有限公司', '通威（海南）水产食品有限公司'],
        ['成都新太丰农业开发有限公司', '成都新太丰农业开发有限公司'],
        ['成都新太丰畜禽养殖', '成都新太丰畜禽养殖'],
        ['山东通威', '山东通威']
      ]
    }

    const options = ref({})

    watch(
      () => props.area,
      () => {
        options.value = {
          header: ['达标企业', '未达标企业'],
          headerHeight: 24,
          oddRowBGC: 'rgba(150,189,240,0.0)',
          evenRowBGC: 'rgba(180,159,200,0.1)',
          rowNum: 9,
          data: dataJson[props.area as string],
          align: ['left', 'left'],
          columnWidth: [205, 205]
        }
      },
      {
        immediate: true
      }
    )

    return {
      options
    }
  }
})
