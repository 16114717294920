import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ceb7e694"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dv_scroll_board = _resolveComponent("dv-scroll-board")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dv_scroll_board, { config: _ctx.options }, null, 8, ["config"])
  ]))
}