
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup() {
    const dataJson = {
      china: 253145.51,
      sichuan: 153145.51,
      chongqing: 353145.51
    }
    return { dataJson }
  }
})
