
import { ref } from 'vue'
import ChartSvg from '@/components/atom/ChartSvg.vue'
import KmSlider from '@/components/atom/KmSlider.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { KmSlider, ChartSvg },
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup() {
    /**
     * 区域数据
     */
    const dataJson = ref({
      china: {
        // 年预计排放
        nyjpf: {
          value: '20,000',
          rate: 1
        },
        // 当前排放
        dqpf: {
          value: '10,000',
          rate: 0.5
        },
        // 年规划耗能
        nghnh: {
          value: '20,000',
          rate: 1
        },
        // 当前耗能
        dqnh: {
          value: '0,000',
          rate: 0.5
        }
      },
      sichuan: {
        // 年预计排放
        nyjpf: {
          value: '10,000',
          rate: 0.5
        },
        // 当前排放
        dqpf: {
          value: '20,000',
          rate: 1
        },
        // 年规划耗能
        nghnh: {
          value: '10,000',
          rate: 1
        },
        // 当前耗能
        dqnh: {
          value: '0,000',
          rate: 0.5
        }
      },
      chongqing: {
        // 年预计排放
        nyjpf: {
          value: '20,000',
          rate: 1
        },
        // 当前排放
        dqpf: {
          value: '10,000',
          rate: 0.5
        },
        // 年规划耗能
        nghnh: {
          value: '20,000',
          rate: 1
        },
        // 当前耗能
        dqnh: {
          value: '0,000',
          rate: 0.5
        }
      }
    })

    return {
      dataJson
    }
  }
})
