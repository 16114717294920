
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    area: {
      type: String,
      default: () => 'china'
    }
  },
  setup(props, { emit }) {
    const now = new Date()
    const week = ['日', '一', '二', '三', '四', '五', '六']
    const time = ref('00:00')
    const getTime = () => {
      const date = new Date()
      time.value = date.getHours() + ':' + date.getMinutes()
    }
    setInterval(getTime, 1000)

    const title = computed(
      () =>
        `${
          props.area === 'china'
            ? '川渝'
            : props.area === 'sichuan'
            ? '四川省'
            : '重庆市'
        }能碳数字公共服务平台`
    )

    return {
      now,
      week,
      time,
      title,
      emit
    }
  }
})
