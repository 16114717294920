
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    hueRotate: {
      type: Number,
      default: 0
    },
  },
  setup(props) {

    const videoFilter = computed(() => {
      return {
        filter: `brightness(130%) saturate(110%) hue-rotate(${props.hueRotate}deg)`
      }
    })

    const svgFilter = computed(() => {
      return {
        filter: `saturate(110%) hue-rotate(${props.hueRotate}deg)`
      }
    })
    return {
      svgFilter,
      videoFilter
    }
  }
})
